.player-full-width {
    width: 100%;
    height: 100%;
    background-color: black;
}

html {
    min-height: 100%;
    padding: 0 !important;
    margin: 0;
}

body {
    min-height: 100%;
    padding: 0;
    margin: 0;
}

#root {
    /*min-height: 100%;*/
    min-height: 100vh;
    /*height: 100%!important;*/
}

.min-height-100vh {
    min-height: 100vh;
}

.webinar-window {
    height: 75vh;
}

.bg-blue-calm {
    background-color: #2a75bb !important;
}

.color-blue-calm {
    color: #2a75bb !important;
}

.bg-black {
    background-color: black;
}

.bg-black-chat {
    background-color: rgba(0, 0, 0, 0.5);
}

.loga-set {
    width: 530px;
}

.partners-logo {
    max-width: 100%;
}

.chat-sizing {
    height: 94vh !important;
}

.chat-sizing-100 {
    height: 100vh !important;
}

.chat-input {
    width: 100%;
    position: absolute;
    bottom: 0px;
}

.chat-heading {
    font-size: 1.5rem;
}

.chat-textarea {
    height: 75px !important;
}

.chat-message {
    word-break: break-word;
}

.chat-replies {
    overflow-y: scroll;
    max-height: 80%;
}

.webinar-name {
    font-size: 1.5rem;
    padding-left: 1rem;
    padding-top: 1rem;
}

.padding-panels {
    padding-bottom: 0.5rem;
}

/* Phone */

@media only screen and (max-width: 768px) {
    html {
        background-color: black;
        padding: 10px;
    }

    .webinar-name {
        font-size: 1rem;
        text-align: center;
        width: 100%;
        padding-bottom: 1rem;
    }

    .webinar-window {
        /*height: 30vh;*/
    }

    .loga-set {
        width: 100%;
    }

    .login-title {
        font-size: 1.25rem;
    }

    .webinar-name {
        text-align: left;
    }

    .register-logo {
        margin-top: 1rem;
    }
}

/* Medium */

@media only screen and (max-width: 992px) {
    .webinar-name {
        padding-left: 1rem;
    }
}

/* Large */

@media only screen and (max-width: 1200px) {
    .webinar-name {
        padding-left: 1rem;
    }

    .dell-panels-logo {
        position: "absolute";
        bottom: "20px"
    }

    .padding-panels {
        padding-bottom: 3rem !important;
    }
}

/* Chat */

@media only screen and (max-height: 750px) {
    .chat-replies {
        max-height: 75%;
    }
}

/* Chat */

@media only screen and (max-height: 550px) {
    .chat-replies {
        max-height: 60%;
    }
}

.bg-img {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.hover-pointer:hover {
    cursor: pointer;
}

.text-bold {
    font-weight: bold;
}

.text-light {
    font-weight: 100;
}

.webinar-panel-title {
    font-size: 16px;
}

.text-underline {
    text-decoration: underline;
}

.dell-panels-logo {
    position: "absolute";
    bottom: "20px"
}

.agenda-font {
    font-size: 1.2rem;
}

.opaque-bg-agenda {
    background-color: rgba(0, 0, 0, 0.4)
}

.ql-align-center {
    text-align: center;
}

.ql-align-right {
    text-align: right;
}

.ql-align-justify {
    text-align: justify;
}

.ql-align-left {
    text-align: left;
}